import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hikaye = ({ user }) => {
    const [tc, setTc] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hikayeText, setHikayeText] = useState("");
    const [adresFoto, setAdresFoto] = useState("");

    const handleSMS = async (e) => {
        e.preventDefault();
        if (user.type !== "Premium" && user.type !== "Admin") {
            return toast.error("Bu Sorguyu Kullanabilmen İçin Premium Satın Almalısın", { autoClose: 3000 });
        }
        setIsSubmitting(true);
        toast.info("Loading...", { autoClose: false });
        try {
            const response = await axios.post(`https://jirgocheck.online/jirgo/api/hikaye`, { tc }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });

            const responseData = response.data.data;
            setHikayeText(responseData.hikayeText);
            setAdresFoto(responseData.adresFoto);

            toast.success(`Veri başarıyla bulundu!`, { autoClose: 3000 });
        } catch (error) {
            toast.dismiss();
            toast.error("Veri bulunamadı.", { autoClose: 3000 });
        } finally {
            toast.dismiss();
            setIsSubmitting(false);
        }
    };

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E',
            color: 'white',
            padding: '20px',
            boxSizing: 'border-box',
            margin: '0 auto'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1000px',
            backgroundColor: '#252526',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        inputGroup: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginBottom: '10px',
            flexWrap: 'wrap'
        },
        textbox: {
            width: '100%',
            padding: '15px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: '#61dafb',
            border: '1px solid #61dafb',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        submitButton: {
            padding: '15px 30px',
            fontSize: '18px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            transition: 'background-color 0.3s ease',
            opacity: isSubmitting ? 0.6 : 1,
            cursor: isSubmitting ? 'not-allowed' : 'pointer',
            width: "100%"
        },
        submitButtonHover: {
            backgroundColor: isSubmitting ? '#61dafb' : '#21a1f1'
        },
        iframeContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            backgroundColor: '#333',
            padding: '20px',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '1000px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        dataText: {
            marginBottom: '20px',
            color: '#61dafb',
            textAlign: 'center'
        },
        image: {
            maxWidth: '100%',
            borderRadius: '10px'
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <h2 style={{ color: '#61dafb' }}>Hayat Hikayesi Sorgu</h2>
            <form style={styles.form} onSubmit={handleSMS}>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        maxLength={11}
                        type="text"
                        onChange={e => setTc(e.target.value)}
                        placeholder="TC"
                        required
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <button
                    type="submit"
                    style={styles.submitButton}
                    disabled={isSubmitting}
                    onMouseEnter={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor)}
                    onMouseLeave={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButton.backgroundColor)}
                >
                    Sorgula
                </button>
            </form>
            <div style={styles.iframeContainer}>
                {hikayeText && <p style={styles.dataText}>{hikayeText}</p>}
                {adresFoto && <img src={`data:image/jpeg;base64,${adresFoto}`} alt="Adres Foto" style={styles.image} />}
            </div>
        </div>
    );
};

export default Hikaye;
