import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = ({ user }) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [apiUsers, setApiUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 5;
    const [searchTerm, setSearchTerm] = useState("");
    const [formData, setFormData] = useState({
        email: '',
        username: '',
        password: '',
        type: '',
        date: '',
    });

    const [apiData, setApiData] = useState({
        username: '',
        password: '',
        auth: '',
        ip: '',
        allowedApis: '',
    });

    const [modalData, setModalData] = useState(null);
    const [modalType, setModalType] = useState(null); // Yeni durum

    useEffect(() => {
        const getUsers = async () => {
            const response = await axios.get("https://jirgocheck.online/jirgo/get/user", {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            console.log(response.data.data);
            setUsers(response.data.data);
            setFilteredUsers(response.data.data);
        };
        getUsers();
    }, []);

    useEffect(() => {
        const results = users.filter(user =>
            user.username.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredUsers(results);
        setCurrentPage(1); // Reset to first page on search
    }, [searchTerm, users]);

    useEffect(() => {
        const getApiUsers = async () => {
            try {
                const response = await axios.get("https://jirgocheck.online/jirgo/get/api/user", {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                    }
                });
                console.log(response.data.data);
                setApiUsers(response.data.data);
            } catch (error) {
                console.log(error);
                toast.warn("API kullanıcıları alınamadı", { autoClose: 3000 });
            }
        };
        getApiUsers();
    }, []);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleDeleteToggle = async (userId) => {
        try {
            await axios.delete(`https://jirgocheck.online/jirgo/delete/user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });

            const updatedUsers = users.filter(user => user._id !== userId);
            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers);
        } catch (error) {
            console.error("Failed to delete user", error);
        }
    };

    const handleApiDeleteToggle = async (userId) => {
        try {
            const response = await axios.delete(`https://jirgocheck.online/jirgo/delete/api/user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            console.log(response.data)
            const updatedApiUsers = apiUsers.filter(user => user._id !== userId);
            setApiUsers(updatedApiUsers);
            toast.success(`API Kullanıcısı Silindi`, { autoClose: 3000 });
        } catch (error) {
            console.error("Failed to delete API user", error);
            toast.error("API Kullanıcısı Silinemedi", { autoClose: 3000 });
        }
    };

    const handleApiUpdateSubmit = async (e, userId) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://jirgocheck.online/jirgo/update/api/user/${userId}`, modalData, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });

            const updatedApiUsers = apiUsers.map(user => user._id === userId ? response.data.data : user);
            setApiUsers(updatedApiUsers);
            setModalData(null);
            setModalType(null); // Modalı kapatmak için
            toast.success("API Kullanıcısı Güncellendi", { autoClose: 3000 });
        } catch (error) {
            console.error("Failed to update API user", error);
            toast.error("API Kullanıcısı Güncellenemedi", { autoClose: 3000 });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://jirgocheck.online/jirgo/create/user", formData, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            setUsers([...users, response.data.data]);
            setFilteredUsers([...users, response.data.data]);
            setFormData({ email: '', username: '', password: '', type: '', date: '' });
            toast.success("Kullanıcı Oluşturuldu", { autoClose: 3000 });
        } catch (error) {
            console.error("Failed to create user", error);
            toast.error("Kullanıcı Oluşturulamadı", { autoClose: 3000 });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
    
        const updatedData = {};
        if (modalData.email !== user.email) updatedData.email = modalData.email;
        if (modalData.username !== user.username) updatedData.username = modalData.username;
        if (modalData.password) updatedData.password = modalData.password; // Password should only be updated if it is changed.
        if (modalData.date !== user.date) updatedData.date = modalData.date;
        if (modalData.type !== user.type) updatedData.type = modalData.type;
    
        try {
            const response = await axios.put(`https://jirgocheck.online/jirgo/update/user/${modalData._id}`, updatedData, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
    
            const updatedUsers = users.map(user => user._id === modalData._id ? response.data.data : user);
            setUsers(updatedUsers);
            setFilteredUsers(updatedUsers);
            setModalData(null);
            setModalType(null);
            toast.success("Kullanıcı Güncellendi", { autoClose: 3000 });
        } catch (error) {
            console.error("Failed to update user", error);
            toast.error("Kullanıcı Güncellenemedi", { autoClose: 3000 });
        }
    };
    

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData({ ...modalData, [name]: value });
    };

    const handleApiInputChange = (e) => {
        const { name, value } = e.target;
        setApiData({ ...apiData, [name]: value });
    };

    const handleApiSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://jirgocheck.online/jirgo/create/api/user`, apiData, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            console.log(response.data);
            toast.success(`Api Kullanıcısı Oluşturuldu: ${response.data.data.username}`, { autoClose: 3000 });
            setApiUsers([...apiUsers, response.data.data]);
            setApiData({ username: '', password: '', auth: '', ip: '', allowedApis: '' });
        } catch (error) {
            console.log(error);
            toast.warn(`Api Oluşturma Başarısız`, { autoClose: 3000 });
        }
    };

    const openUserModal = (user) => {
        setModalData(user);
        setModalType("user"); // Modal türünü ayarla
    };

    const openApiUserModal = (apiUser) => {
        setModalData(apiUser);
        setModalType("apiUser"); // Modal türünü ayarla
    };

    const closeModal = () => {
        setModalData(null);
        setModalType(null); // Modal türünü sıfırla
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#001f3f',
            color: '#00ff00',
            padding: '20px',
        },
        header: {
            textAlign: 'center',
            border: '1px solid #00ff00',
            padding: '20px',
            borderRadius: '5px',
            width: '100%',
            maxWidth: '800px',
            margin: '20px 0',
            backgroundColor: '#001f3f',
        },
        searchWrapper: {
            width: '100%',
            maxWidth: '800px',
            marginBottom: '20px',
        },
        searchInput: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #00ff00',
            backgroundColor: '#011627',
            color: '#00ff00',
        },
        tableWrapper: {
            width: '100%',
            maxWidth: '800px',
            overflowX: 'auto',
            margin: '20px 0',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            backgroundColor: '#001f3f',
            color: '#00ff00',
        },
        tableHeader: {
            backgroundColor: '#011627',
            color: '#00ff00',
            padding: '10px',
            textAlign: 'center',
            borderBottom: '2px solid #00ff00',
        },
        tableCell: {
            backgroundColor: '#011627',
            color: '#00ff00',
            padding: '10px',
            borderBottom: '1px solid #00ff00',
            textAlign: 'center',
        },
        button: {
            padding: '5px 10px',
            margin: '5px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            backgroundColor: '#00ff00',
            color: '#000',
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            color: '#00ff00',
        },
        paginationButton: {
            padding: '10px 15px',
            backgroundColor: '#00ff00',
            color: '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            margin: '0 5px',
        },
        paginationDisabled: {
            backgroundColor: '#444',
            cursor: 'not-allowed',
        },
        formWrapper: {
            width: '100%',
            maxWidth: '800px',
            marginTop: '20px',
            padding: '20px',
            border: '1px solid #00ff00',
            borderRadius: '5px',
            backgroundColor: '#001f3f',
        },
        formField: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #00ff00',
            backgroundColor: '#011627',
            color: '#00ff00',
            marginBottom: '10px',
        },
        formButton: {
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '5px',
            border: 'none',
            backgroundColor: '#00ff00',
            color: '#000',
            cursor: 'pointer',
        },
        modal: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#001f3f',
            padding: '20px',
            borderRadius: '5px',
            border: '1px solid #00ff00',
            zIndex: 1000,
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000,
        },
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <div style={styles.header}>
                <h1>Jirgo Checker Admin Panel</h1>
                <p>Hoşgeldin, {user?.name}!</p>
            </div>
            <div style={styles.searchWrapper}>
                <input
                    type="text"
                    placeholder="Search By Username"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={styles.searchInput}
                />
            </div>

            <h2>All Users {users.length}</h2>
            <div style={styles.tableWrapper}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableHeader}>Email</th>
                            <th style={styles.tableHeader}>Username</th>
                            <th style={styles.tableHeader}>Type</th>
                            <th style={styles.tableHeader}>Kayıt Tarihi</th>
                            <th style={styles.tableHeader}>Son Giriş</th>
                            <th style={styles.tableHeader}>User Agent</th>
                            <th style={styles.tableHeader}>IP Address</th>
                            <th style={styles.tableHeader}>Device</th>
                            <th style={styles.tableHeader}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.map(user => (
                            <tr key={user._id}>
                                <td style={styles.tableCell}>{user.email}</td>
                                <td style={styles.tableCell}>{user.username}</td>
                                <td style={styles.tableCell}>{user.type}</td>
                                <td style={styles.tableCell}>{user.registeredDate}</td>
                                <td style={styles.tableCell}>{user.lastLoginDate}</td>
                                <td style={styles.tableCell}>{user.userAgent}</td>
                                <td style={styles.tableCell}>{user.ipAdres}</td>
                                <td style={styles.tableCell}>{user.device}</td>
                                <td style={styles.tableCell}>
                                    <button
                                        style={styles.button}
                                        onClick={() => openUserModal(user)}
                                    >
                                        Güncelle
                                    </button>
                                    <button
                                        style={styles.button}
                                        onClick={() => handleDeleteToggle(user._id)}
                                    >
                                        Hesabı Sil
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {filteredUsers.length > usersPerPage && (
                <div style={styles.pagination}>
                    <button
                        onClick={() => paginate(currentPage - 1)}
                        style={{ ...styles.paginationButton, ...(currentPage === 1 ? styles.paginationDisabled : {}) }}
                        disabled={currentPage === 1}
                    >
                        ← Önceki
                    </button>
                    <button
                        onClick={() => paginate(currentPage + 1)}
                        style={{ ...styles.paginationButton, ...(currentPage === Math.ceil(filteredUsers.length / usersPerPage) ? styles.paginationDisabled : {}) }}
                        disabled={currentPage === Math.ceil(filteredUsers.length / usersPerPage)}
                    >
                        Sonraki →
                    </button>
                </div>
            )}

            <div style={styles.formWrapper}>
                <h2>Create New User</h2>
                <form onSubmit={handleFormSubmit}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="text"
                        name="type"
                        placeholder="Type"
                        value={formData.type}
                        onChange={handleInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        style={styles.formField}
                        required
                    />
                    <button type="submit" style={styles.formButton}>Create User</button>
                </form>
            </div>
            <br />
            <div style={styles.formWrapper}>
                <h2>Create Api User</h2>
                <form onSubmit={handleApiSubmit}>
                    <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={apiData.username}
                        onChange={handleApiInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={apiData.password}
                        onChange={handleApiInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="text"
                        name="auth"
                        placeholder="Auth Key"
                        value={apiData.auth}
                        onChange={handleApiInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="text"
                        name="ip"
                        placeholder="IP Address"
                        value={apiData.ip}
                        onChange={handleApiInputChange}
                        style={styles.formField}
                        required
                    />
                    <input
                        type="text"
                        name="allowedApis"
                        placeholder="Allowed APIs"
                        value={apiData.allowedApis}
                        onChange={handleApiInputChange}
                        style={styles.formField}
                        required
                    />
                    <button type="submit" style={styles.formButton}>Create Api User</button>
                </form>
            </div>

            <h2>API Users</h2>
            <div style={styles.tableWrapper}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableHeader}>ID</th>
                            <th style={styles.tableHeader}>Username</th>
                            <th style={styles.tableHeader}>Auth Key</th>
                            <th style={styles.tableHeader}>IP Address</th>
                            <th style={styles.tableHeader}>Allowed APIs</th>
                            <th style={styles.tableHeader}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiUsers.map(user => (
                            <tr key={user._id}>
                                <td style={styles.tableCell}>{user._id}</td>
                                <td style={styles.tableCell}>{user.username}</td>
                                <td style={styles.tableCell}>{user.auth}</td>
                                <td style={styles.tableCell}>{user.ip}</td>
                                <td style={styles.tableCell}>{user.allowedApis.join(", ")}</td>
                                <td style={styles.tableCell}>
                                    <button
                                        style={styles.button}
                                        onClick={() => openApiUserModal(user)}
                                    >
                                        Güncelle
                                    </button>
                                    <button
                                        style={styles.button}
                                        onClick={() => handleApiDeleteToggle(user._id)}
                                    >
                                        Sil
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {modalType === "user" && modalData && (
                <div style={styles.overlay}>
                    <div style={styles.modal}>
                        <h2>Update User</h2>
                        <form onSubmit={handleUpdateSubmit}>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={modalData.email || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={modalData.username || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="text"
                                name="password"
                                placeholder="Password"
                                value={modalData.password || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="text"
                                name="type"
                                placeholder="Type"
                                value={modalData.type || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="date"
                                name="date"
                                value={modalData.date || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <button type="submit" style={styles.formButton}>Update User</button>
                        </form>
                        <button onClick={closeModal} style={styles.formButton}>Close</button>
                    </div>
                </div>
            )}

            {modalType === "apiUser" && modalData && (
                <div style={styles.overlay}>
                    <div style={styles.modal}>
                        <h2>Update API User</h2>
                        <form onSubmit={(e) => handleApiUpdateSubmit(e, modalData._id)}>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={modalData.username || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={modalData.password || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="text"
                                name="auth"
                                placeholder="Auth Key"
                                value={modalData.auth || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="text"
                                name="ip"
                                placeholder="IP Address"
                                value={modalData.ip || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <input
                                type="text"
                                name="allowedApis"
                                placeholder="Allowed APIs"
                                value={modalData.allowedApis || ""}
                                onChange={handleModalChange}
                                style={styles.formField}
                            />
                            <button type="submit" style={styles.formButton}>Update API User</button>
                        </form>
                        <button onClick={closeModal} style={styles.formButton}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
