import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div style={{ color: 'white', backgroundColor: 'black', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "auto" }}>
            <div style={{ textAlign: 'center', border: '1px solid #00ff00', padding: '20px', borderRadius: '5px', width: "1000px", height: "250px", justifyContent: "center" }}>
                <h1 style={{ color: '#00ff00' }}>404</h1>
                <h2 style={{ color: '#00ff00' }}>Jirgo Checker</h2>
                <p style={{ color: 'white' }}>Bu Sayfa Bulunamadı</p>
                <button style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: '#00ff00',
                    color: 'black',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Geri Dön</Link>
                </button>
            </div>
        </div>
    );
}

export default NotFound;
