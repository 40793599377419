import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet, Navigate } from 'react-router';
import Login from './Login';

const ProtectedRoute = ({ setUser, user }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get(`https://jirgocheck.online/jirgo/api/auth`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                    }
                });

                if (response.data.user.type === "Admin") {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }

                setUser(response.data.user);
                setIsAuth(true);
            } catch (error) {
                setIsAuth(false);
                setIsAdmin(false);
            }
        };

        checkAuth();
    }, [setUser]);

    if (!isAuth) {
        return <Login />;
    }

    if (window.location.pathname === '/dashboard' && !isAdmin) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
