import React from 'react';

const Iban = () => {

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E', // Güncellenmiş arka plan rengi
            color: '#61dafb', // Güncellenmiş metin rengi
            padding: '20px',
            boxSizing: 'border-box'
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
            fontSize: '24px',
            color: '#61dafb' // Başlık rengini güncelledim
        },
        iframeContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        iframe: {
            border: '2px solid #61dafb', // Güncellenmiş çerçeve rengi
            borderRadius: '10px', // Köşeleri yuvarlat
            width: '100%',
            maxWidth: '600px',
            height: '500px', // Daha büyük bir yükseklik
            backgroundColor: '#252526' // Güncellenmiş iframe arka plan rengi
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>İban Sorgu</h2>
            <div style={styles.iframeContainer}>
                <iframe
                    title="hesapno.com iban çözümleme modülü"
                    src="https://hesapno.com/mod_iban_coz"
                    name="hesapno.com iban çözümleme modülü"
                    scrolling="auto"
                    style={styles.iframe}
                ></iframe>
            </div>
        </div>
    );
};

export default Iban;
