import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TcSorgu = () => {

    const [tc, setTc] = useState("");
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dataPerPage = 5;

    const handleTcSorgu = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        toast.info("Loading...", { autoClose: false });
        try {
            const response = await axios.post(`https://jirgocheck.online/jirgo/api/tcsorgu`, { tc }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            setData(response.data.data);
            setCurrentPage(0);
            toast.dismiss();
            if (response.data.data.length > 0) {
                toast.success(`Veri başarıyla bulundu! Toplam Kayıt: ${response.data.data.length}`, { autoClose: 3000 });
            } else {
                toast.warn("Veri bulunamadı.", { autoClose: 3000 });
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Veri bulunamadı.", { autoClose: 3000 });
            setData([]);
        } finally {
            setIsSubmitting(false);
        }
    };

    const offset = currentPage * dataPerPage;
    const currentData = data.slice(offset, offset + dataPerPage);

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E',
            color: 'white',
            padding: '20px',
            boxSizing: 'border-box',
            margin: '0 auto'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1000px',
            backgroundColor: '#252526',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        inputGroup: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '10px',
            flexWrap: 'wrap'
        },
        textbox: {
            width: '100%',
            padding: '15px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: '#61dafb',
            border: '1px solid #61dafb',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        submitButton: {
            padding: '15px 30px',
            fontSize: '18px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            transition: 'background-color 0.3s ease',
            opacity: isSubmitting ? 0.6 : 1,
            cursor: isSubmitting ? 'not-allowed' : 'pointer'
        },
        submitButtonHover: {
            backgroundColor: isSubmitting ? '#61dafb' : '#21a1f1'
        },
        tableWrapper: {
            width: '100%',
            maxWidth: '1300px',
            overflowX: 'auto',
            margin: '20px auto'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            border: '1px solid #61dafb',
        },
        tableHeader: {
            backgroundColor: '#333',
            color: '#61dafb',
            padding: '10px',
            textAlign: 'center',
            borderBottom: '2px solid #61dafb'
        },
        tableCell: {
            backgroundColor: '#444',
            color: '#61dafb',
            padding: '10px',
            borderBottom: '1px solid #61dafb',
            textAlign: 'center',
            border: '1px solid #61dafb'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '300px',
            color: '#61dafb'
        },
        paginationButton: {
            padding: '10px 15px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            margin: '0 5px'
        },
        paginationButtonHover: {
            backgroundColor: '#21a1f1'
        },
        paginationDisabled: {
            backgroundColor: '#444',
            cursor: 'not-allowed'
        },
        '@media (max-width: 600px)': {
            inputGroup: {
                flexDirection: 'column'
            },
            textbox: {
                width: '100%',
                marginBottom: '10px'
            },
            tableWrapper: {
                padding: '0 10px'
            },
            table: {
                width: 'auto'
            }
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <h2 style={{ color: '#61dafb' }}>TC Sorgu</h2>
            <form style={styles.form} onSubmit={handleTcSorgu}>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        maxLength={11}
                        type="text"
                        onChange={e => setTc(e.target.value)}
                        placeholder="TC"
                        required
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <button
                    type="submit"
                    style={styles.submitButton}
                    disabled={isSubmitting}
                    onMouseEnter={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor)}
                    onMouseLeave={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButton.backgroundColor)}
                >
                    Sorgula
                </button>
            </form>
            <div style={styles.tableWrapper}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableHeader}>TC</th>
                            <th style={styles.tableHeader}>ADI</th>
                            <th style={styles.tableHeader}>SOYADI</th>
                            <th style={styles.tableHeader}>DOGUMTARIHI</th>
                            <th style={styles.tableHeader}>NUFUSIL</th>
                            <th style={styles.tableHeader}>NUFUSILCE</th>
                            <th style={styles.tableHeader}>ANNEADI</th>
                            <th style={styles.tableHeader}>ANNETC</th>
                            <th style={styles.tableHeader}>BABAADI</th>
                            <th style={styles.tableHeader}>BABATC</th>
                            <th style={styles.tableHeader}>UYRUK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.length > 0 ? currentData.map((item, index) => (
                            <tr key={index}>
                                <td style={styles.tableCell}>{item.TC}</td>
                                <td style={styles.tableCell}>{item.ADI}</td>
                                <td style={styles.tableCell}>{item.SOYADI}</td>
                                <td style={styles.tableCell}>{item.DOGUMTARIHI}</td>
                                <td style={styles.tableCell}>{item.NUFUSIL}</td>
                                <td style={styles.tableCell}>{item.NUFUSILCE}</td>
                                <td style={styles.tableCell}>{item.ANNEADI}</td>
                                <td style={styles.tableCell}>{item.ANNETC}</td>
                                <td style={styles.tableCell}>{item.BABAADI}</td>
                                <td style={styles.tableCell}>{item.BABATC}</td>
                                <td style={styles.tableCell}>{item.UYRUK}</td>
                            </tr>
                        )) : (
                            <tr>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TcSorgu;
