import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Jirgo from './jirgocheck.jpg';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const Register = () => {
    const [formData, setFormdata] = useState({
        email: '',
        username: '',
        password: '',
        referans: '',
        recaptchaToken: '',
    });

    const handleRecaptchaChange = (token) => {
        setFormdata({ ...formData, recaptchaToken: token });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`https://jirgocheck.online/jirgo/api/register`, formData);
            toastr.success('Kayıt Başarılı Yönlendiriliyorsunuz...');
            setTimeout(() => {
                window.location = '/login';
            }, 3000);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toastr.error(error.response.data.message);
            } else {
                toastr.error('An error occurred. Please try again.');
            }
        }
    };

    return (
        <div className="container-fluid position-fixed top-0 left-0 right-0 bottom-0 bg-black">
            <div className="row h-100 align-items-center justify-content-center">
                <div className="bg-dark col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="rounded p-4 p-sm-5 mx-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <Link to="/register" className="text-decoration-none">
                                <h3 className="text-black text-decoration-none">
                                    <img src={Jirgo} alt="Jirgo" width="70px" />
                                    Jirgo
                                </h3>
                            </Link>
                            <h3 className="text-white text-decoration-none">Kayıt Ol</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-floating mb-4">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    onChange={(e) =>
                                        setFormdata({ ...formData, email: e.target.value })
                                    }
                                    required
                                />
                                <label htmlFor="floatingInput" className="color-gray bg-gray text-gray">
                                    Email
                                </label>
                            </div>
                            <div className="form-floating mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Username"
                                    onChange={(e) =>
                                        setFormdata({ ...formData, username: e.target.value })
                                    }
                                    required
                                />
                                <label htmlFor="floatingInput" className="color-gray bg-gray text-gray">
                                    Username
                                </label>
                            </div>
                            <div className="form-floating mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Referans Kod"
                                    onChange={(e) =>
                                        setFormdata({ ...formData, referans: e.target.value })
                                    }
                                    required
                                />
                                <label htmlFor="floatingInput" className="color-gray bg-gray text-gray">
                                    Referans Kodu
                                </label>
                            </div>
                            <div className="form-floating mb-4">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    onChange={(e) =>
                                        setFormdata({ ...formData, password: e.target.value })
                                    }
                                    required
                                />
                                <label htmlFor="floatingInput" className="color-gray bg-gray text-gray">
                                    Password
                                </label>
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <ReCAPTCHA
                                    sitekey="6LcQfecpAAAAAJ_gqd2EIS97yVgRkuVPFBA7Z0tb"
                                    onChange={handleRecaptchaChange}
                                />
                            </div>
                            <button className="btn bg-black text-white py-3 w-100 mb-4">
                                Kayıt Ol
                            </button>
                            <div className="d-flex justify-content-between">
                                <a
                                    href="https://discord.com/invite/jirgo"
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        borderRadius: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px 15px',
                                        backgroundColor: '#7289da',
                                        textDecoration: 'none',
                                        justifyContent: 'center',
                                        flex: 1,
                                        marginRight: '10px',
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div style={{ width: 25, height: 25, marginRight: 15 }}>
                                        <svg
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 245 240"
                                        >
                                            <path
                                                className="st0"
                                                d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"
                                            />
                                            <path
                                                className="st0"
                                                d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                                            />
                                        </svg>
                                    </div>
                                    <span>Discord</span>
                                </a>
                                <a
                                    href="https://t.me/jirgocheck"
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        borderRadius: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px 15px',
                                        backgroundColor: '#0088cc',
                                        textDecoration: 'none',
                                        justifyContent: 'center',
                                        flex: 1,
                                        marginLeft: '10px',
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div style={{ width: 25, height: 25, marginRight: 15 }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-send"
                                        >
                                            <line x1="22" y1="2" x2="11" y2="13"></line>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                        </svg>
                                    </div>
                                    <span>Telegram</span>
                                </a>
                            </div>
                        </form>
                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                            }}
                        >
                            Hesabın var mı?
                            <Link
                                to="/login"
                                style={{ textDecoration: 'none', color: 'cyan', marginLeft: '5px' }}
                            >
                                Giriş Yap
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
