import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Esnaf = ({user}) => {

    const [gsm, setGsm] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSMS = async (e) => {
        e.preventDefault();
        if (user.type !== "Premium" && user.type !== "Admin") {
            return toast.error("Bu Sorguyu Kullanabilmen İçin Premium Satın Almalısın", { autoClose: 3000 });
        }
        if (gsm.length !== 10) {
            return toast.error(`GSM 10 Haneden Oluşmalıdır`, { autoClose: 3000 });
        }
        setIsSubmitting(true);
        toast.success(`SMS Saldırısı Başlatılıyor...`, { autoClose: 3000 });
        try {
            await axios.post(`https://jirgocheck.online/jirgo/api/sms`, { gsm }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });

        } catch (error) {
            toast.dismiss();
            toast.error("Saldırı Başarısız", { autoClose: 3000 });
        } finally {
            setIsSubmitting(false);
        }
    };

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E',
            color: 'white',
            padding: '20px',
            boxSizing: 'border-box',
            margin: '0 auto'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1000px',
            backgroundColor: '#252526',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        inputGroup: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '10px',
            flexWrap: 'wrap'
        },
        textbox: {
            width: '100%',
            padding: '15px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: '#61dafb',
            border: '1px solid #61dafb',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        submitButton: {
            padding: '15px 30px',
            fontSize: '18px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            transition: 'background-color 0.3s ease',
            opacity: isSubmitting ? 0.6 : 1,
            cursor: isSubmitting ? 'not-allowed' : 'pointer'
        },
        submitButtonHover: {
            backgroundColor: isSubmitting ? '#61dafb' : '#21a1f1'
        },
        tableWrapper: {
            width: '100%',
            maxWidth: '1300px',
            overflowX: 'auto',
            margin: '20px auto'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            border: '1px solid #61dafb',
        },
        tableHeader: {
            backgroundColor: '#333',
            color: '#61dafb',
            padding: '10px',
            textAlign: 'center',
            borderBottom: '2px solid #61dafb'
        },
        tableCell: {
            backgroundColor: '#444',
            color: '#61dafb',
            padding: '10px',
            borderBottom: '1px solid #61dafb',
            textAlign: 'center',
            border: '1px solid #61dafb'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '300px',
            color: '#61dafb'
        },
        paginationButton: {
            padding: '10px 15px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            margin: '0 5px'
        },
        paginationButtonHover: {
            backgroundColor: '#21a1f1'
        },
        paginationDisabled: {
            backgroundColor: '#444',
            cursor: 'not-allowed'
        },
        '@media (max-width: 600px)': {
            inputGroup: {
                flexDirection: 'column'
            },
            textbox: {
                width: '100%',
                marginBottom: '10px'
            },
            tableWrapper: {
                padding: '0 10px'
            },
            table: {
                width: 'auto'
            }
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <h2 style={{ color: '#61dafb' }}>SMS Bomber</h2>
            <form style={styles.form} onSubmit={handleSMS}>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        maxLength={10}
                        type="text"
                        onChange={e => setGsm(e.target.value)}
                        placeholder="GSM"
                        required
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <button
                    type="submit"
                    style={styles.submitButton}
                    disabled={isSubmitting}
                    onMouseEnter={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor)}
                    onMouseLeave={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButton.backgroundColor)}
                >
                    Saldırı Başlat
                </button>
            </form>
        </div>
    );
};

export default Esnaf;
