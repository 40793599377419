import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdaParsel = ({user}) => {
    const [formData, setFormData] = useState({
        il: "",
        ilce: "",
        mahalle: "",
        ada: "",
        parsel: ""
    });

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state variable for button disabled state
    const dataPerPage = 5;

    const handleAdaParsel = async (e) => {
        e.preventDefault();
        if (user.type !== "Premium" && user.type !== "Admin") {
            return toast.error("Bu Sorguyu Kullanabilmen İçin Premium Satın Almalısın", { autoClose: 3000 });
        }
        setIsSubmitting(true); // Disable the button
        toast.info("Loading...", { autoClose: false });
        try {
            const response = await axios.get(`https://jirgocheck.online/apiservices/adaparsel/jirgo?il=${formData.il}&ilce=${formData.ilce}&mahalle=${formData.mahalle}&ada=${formData.ada}&parsel=${formData.parsel}`);
            console.log(response.data)
            setData(response.data.data);
            setCurrentPage(0);
            toast.dismiss();
            if (response.data.data.length > 0) {
                toast.success(`Veri başarıyla bulundu! Toplam Kayıt: ${response.data.data.length}`, { autoClose: 3000 });
            } else {
                toast.warn("Veri bulunamadı.", { autoClose: 3000 });
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Veri bulunamadı.", { autoClose: 3000 });
            setData([]); // Clear the data if there's an error
        } finally {
            toast.dismiss();
            setIsSubmitting(false); // Enable the button
        }
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * dataPerPage;
    const currentData = data.slice(offset, offset + dataPerPage);

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E',
            color: 'white',
            padding: '20px',
            boxSizing: 'border-box',
            margin: '0 auto' // Center the container
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1000px',
            backgroundColor: '#252526',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        inputGroup: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '10px',
            flexWrap: 'wrap', // Allow items to wrap
        },
        textbox: {
            flex: '1 1 48%', // Flexible width for input fields
            padding: '15px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: '#61dafb',
            border: '1px solid #61dafb',
            borderRadius: '5px',
            marginBottom: '10px' // Margin for spacing on smaller screens
        },
        submitButton: {
            padding: '15px 30px',
            fontSize: '18px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            transition: 'background-color 0.3s ease',
            opacity: isSubmitting ? 0.6 : 1, // Change opacity when disabled
            cursor: isSubmitting ? 'not-allowed' : 'pointer' // Change cursor when disabled
        },
        submitButtonHover: {
            backgroundColor: isSubmitting ? '#61dafb' : '#21a1f1' // Prevent hover effect when disabled
        },
        tableWrapper: {
            width: '100%',
            maxWidth: '1300px',
            overflowX: 'auto',
            margin: '20px auto' // Center the table wrapper
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            border: '1px solid #61dafb',
        },
        tableHeader: {
            backgroundColor: '#333',
            color: '#61dafb',
            padding: '10px',
            textAlign: 'center',
            borderBottom: '2px solid #61dafb'
        },
        tableCell: {
            backgroundColor: '#444',
            color: '#61dafb',
            padding: '10px',
            borderBottom: '1px solid #61dafb',
            textAlign: 'center',
            border: '1px solid #61dafb'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '300px',
            color: '#61dafb'
        },
        paginationButton: {
            padding: '10px 15px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            margin: '0 5px'
        },
        paginationButtonHover: {
            backgroundColor: '#21a1f1'
        },
        paginationDisabled: {
            backgroundColor: '#444',
            cursor: 'not-allowed'
        },
        '@media (max-width: 600px)': {
            inputGroup: {
                flexDirection: 'column'
            },
            textbox: {
                width: '100%',
                marginBottom: '10px'
            },
            tableWrapper: {
                padding: '0 10px'
            },
            table: {
                width: 'auto'
            }
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <h2 style={{ color: '#61dafb' }}>Ada Parsel</h2>
            <form style={styles.form} onSubmit={handleAdaParsel}>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        type="text"
                        name="İl"
                        onChange={e => setFormData({ ...formData, il: e.target.value })}
                        placeholder="İl"
                        style={styles.textbox}
                        required
                        className="textbox"
                    />
                    <input
                        type="text"
                        name="İlçe"
                        onChange={e => setFormData({ ...formData, ilce: e.target.value })}
                        placeholder="İlçe"
                        style={styles.textbox}
                        required
                        className="textbox"
                    />
                </div>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        type="text"
                        name="Mahalle"
                        onChange={e => setFormData({ ...formData, mahalle: e.target.value })}
                        placeholder="Mahalle"
                        style={styles.textbox}
                        className="textbox"
                    />
                    <input
                        type="text"
                        name="Ada"
                        onChange={e => setFormData({ ...formData, ada: e.target.value })}
                        placeholder="Ada"
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        type="text"
                        name="Parsel"
                        onChange={e => setFormData({ ...formData, parsel: e.target.value })}
                        placeholder="Parsel"
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <button
                    type="submit"
                    style={styles.submitButton}
                    disabled={isSubmitting} // Disable the button
                    onMouseEnter={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor)}
                    onMouseLeave={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButton.backgroundColor)}
                >
                    Sorgula
                </button>
            </form>
            <div style={styles.tableWrapper}>
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th style={styles.tableHeader}>TC</th>
                            <th style={styles.tableHeader}>Ad</th>
                            <th style={styles.tableHeader}>Soyad</th>
                            <th style={styles.tableHeader}>Baba Adı</th>
                            <th style={styles.tableHeader}>İl</th>
                            <th style={styles.tableHeader}>İlçe</th>
                            <th style={styles.tableHeader}>Mahalle</th>
                            <th style={styles.tableHeader}>Zemin Tipi</th>
                            <th style={styles.tableHeader}>Ada</th>
                            <th style={styles.tableHeader}>Parsel</th>
                            <th style={styles.tableHeader}>Yüz Ölçümü</th>
                            <th style={styles.tableHeader}>Ana Tasinmaz Nitelik</th>
                            <th style={styles.tableHeader}>Blok</th>
                            <th style={styles.tableHeader}>Bagimsiz Bolum No</th>
                            <th style={styles.tableHeader}>Arsa Pay</th>
                            <th style={styles.tableHeader}>Arsa Payda</th>
                            <th style={styles.tableHeader}>Bagimsiz Bolum Nitelik</th>
                            <th style={styles.tableHeader}>Istirak No</th>
                            <th style={styles.tableHeader}>Hisse Pay</th>
                            <th style={styles.tableHeader}>Hisse Payda</th>
                            <th style={styles.tableHeader}>Edinme Sebebi</th>
                            <th style={styles.tableHeader}>Tapu Tarihi</th>
                            <th style={styles.tableHeader}>Yevmiye</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.length > 0 ? (
                            currentData.map((item, index) => (
                                <tr key={index}>
                                    <td style={styles.tableCell}>{item.Identify}</td>
                                    <td style={styles.tableCell}>{item.Name}</td>
                                    <td style={styles.tableCell}>{item.Surname}</td>
                                    <td style={styles.tableCell}>{item.BabaAdi}</td>
                                    <td style={styles.tableCell}>{item.İlBilgisi}</td>
                                    <td style={styles.tableCell}>{item.İlceBilgisi}</td>
                                    <td style={styles.tableCell}>{item.MahalleBilgisi}</td>
                                    <td style={styles.tableCell}>{item.ZeminTipBilgisi}</td>
                                    <td style={styles.tableCell}>{item.AdaBilgisi}</td>
                                    <td style={styles.tableCell}>{item.ParselBilgisi}</td>
                                    <td style={styles.tableCell}>{item.YuzolcumBilgisi}</td>
                                    <td style={styles.tableCell}>{item.AnaTasinmazNitelik}</td>
                                    <td style={styles.tableCell}>{item.BlokBilgisi}</td>
                                    <td style={styles.tableCell}>{item.BagimsizBolumNo}</td>
                                    <td style={styles.tableCell}>{item.ArsaPay}</td>
                                    <td style={styles.tableCell}>{item.ArsaPayda}</td>
                                    <td style={styles.tableCell}>{item.BagimsizBolumNitelik}</td>
                                    <td style={styles.tableCell}>{item.IstirakNo}</td>
                                    <td style={styles.tableCell}>{item.HissePay}</td>
                                    <td style={styles.tableCell}>{item.HissePayda}</td>
                                    <td style={styles.tableCell}>{item.EdinmeSebebi}</td>
                                    <td style={styles.tableCell}>{item.TapuDate}</td>
                                    <td style={styles.tableCell}>{item.Yevmiye}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                {data.length > dataPerPage && (
                                    <div style={styles.pagination}>
                                        <button
                                            onClick={() => handlePageClick({ selected: currentPage - 1 })}
                                            style={{
                                                ...styles.paginationButton,
                                                ...(currentPage === 0 ? styles.paginationDisabled : {})
                                            }}
                                            disabled={currentPage === 0}
                                        >
                                            ← Önceki
                                        </button>
                                        <button
                                            onClick={() => handlePageClick({ selected: currentPage + 1 })}
                                            style={{
                                                ...styles.paginationButton,
                                                ...(currentPage === Math.ceil(data.length / dataPerPage) - 1 ? styles.paginationDisabled : {})
                                            }}
                                            disabled={currentPage === Math.ceil(data.length / dataPerPage) - 1}
                                        >
                                            Sonraki →
                                        </button>
                                    </div>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdaParsel;
